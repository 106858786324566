import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import HowItWorks from '../../HowItWorks/Containers/HowItWorks'
import MedicalInfo from '../../MedicalInfo/Containers/MedicalInfo'
import Services from '../../Services/Containers/Services'

class MainRouter extends Component {
  render() {
    return (
        <Switch>
            <Route exact path='/' component={HowItWorks}/>
            <Route path='/howitworks' component={HowItWorks}/>
            <Route path='/services' component={Services}/>
            <Route path='/medicalInfo' component={MedicalInfo}/>
        </Switch>
    );
  }
}

export default MainRouter;