import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import SingInModal from '../Containers/SignInModal'

class JoinUs extends Component {
  constructor(props){
    super(props);
    this.state={
      showModal:false
    }
  }

  handleCloseModal(){
    this.setState({showModal:false});
  }

  handleOpenModal(){
    this.setState({showModal:true});
  }

  render() {
    return (
      <div className="JoinUs">
        <Button bsStyle="info" onClick={this.handleOpenModal.bind(this)}>Join us or Sign in</Button>
        <SingInModal show={this.state.showModal}
                     close={this.handleCloseModal.bind(this)}
        />
      </div>
    );
  }
}

export default JoinUs;