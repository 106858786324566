import React, { Component } from 'react';

class MedicalInfo extends Component {
  render() {
    return (
      <div className="medical-info">
        <h1>Medical info</h1>
      </div>
    );
  }
}

export default MedicalInfo;