import React, { Component } from 'react';
import JoinUs from '../../Shared/Components/JoinUs';

class Cover extends Component {
  render() {
    return (
      <div className="container">
        <div className="cover">
            <span className="middle-element">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <a href="#medicalService" className="info">
                            Your personal 24/7 medical service
                        </a>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <JoinUs/>
                    </div>
                </div>
            </span>
        </div>
      </div>
    );
  }
}

export default Cover;