import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap'
import MenuItem from '../Components/MenuItem'
import MenuDropdownItem from '../Components/MenuDropdownItem'
import Logo from '../../../resources/images/logo.png'


class Menu extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const dropdownItems = [
        {title:"how it works", eventKey:6.1, link:'howitworks'},
        {title:"services", eventKey:6.2, link:'services'},
        {title:"medical info", eventKey:6.3, link:'medicalInfo'}
    ];
    return (
        <nav className="navbar">
            <div className="container">
                <Navbar.Header>
                    <Navbar.Brand>
                        <a href="#brand">
                            <span>
                                <img src={Logo}/>
                            </span>
                        </a>
                    </Navbar.Brand>
                </Navbar.Header>
                <Nav pullRight className="navbar-nav">
                    <MenuItem eventKey={1}
                                title="How it works?"
                    />
                    <MenuItem eventKey={2}
                                title="Our services"
                    />
                    <MenuItem eventKey={3}
                                title="What we treat?"
                    />
                    <MenuItem eventKey={4}
                                title="Medical info"
                    />
                    <MenuItem eventKey={5}
                                title="About us"
                    />
                    <MenuDropdownItem eventKey={6}
                                      title='Links'
                                      items={dropdownItems}
                    />
                </Nav>
            </div>
        </nav> 
    );
  }
}

export default Menu;