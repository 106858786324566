import React, { Component } from 'react';
import Menu from './Menu'

class Header extends Component {
  constructor(props){
    super(props);
    //console.log(process.env.NODE_ENV);
  }
  render() {
    return (
      <div className="header">
        <Menu/>
      </div>
    );
  }
}

export default Header;