import React, { Component } from 'react';
import { NavDropdown, MenuItem, NavItem } from 'react-bootstrap'

class MenuDropdownItem extends Component {
    constructor(props){
      super(props);
    }
    render() {
      return (
        <NavDropdown eventKey={this.props.eventKey} title={this.props.title} id="basic-nav-dropdown">
          {
            this.props.items?
            (
              this.props.items.map((item)=> 
                <NavItem eventKey={item.eventKey} href={item.link}>{item.title}</NavItem>
              )
            )
            :
            null
          }
        </NavDropdown>
      );
    }
  }
  
  export default MenuDropdownItem;