import React, { Component } from 'react';


class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        Iborn.net Skopje Varshavska 9a
      </footer>
    );
  }
}

export default Footer;