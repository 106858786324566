import React, { Component } from 'react';
import Cover from '../Components/Cover'
import BottomInfo from '../Components/BottomInfo'


class HowItWorks extends Component {
  render() {
    return (
      <div className="how-it-works">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <Cover />
          </div>
          <div className="col-md-12 col-sm-12">
            <BottomInfo/>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;