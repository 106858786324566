import React, { Component } from 'react';
import { Modal, Button, Form, FormGroup, Col, FormControl, Checkbox } from 'react-bootstrap';

class SignInModal extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
        <Modal show={this.props.show} onHide={this.props.close}>
            <Modal.Body>
                <div class="sign-in-modal">
                    <Col sm={12} className="header">
                        <h3>Sign in</h3>
                    </Col>
                    <Form horizontal>
                        <FormGroup controlId="formHorizontalEmail">
                            <Col smOffset={1} sm={10}>
                                <FormControl type="email" placeholder="Email" />
                            </Col>
                        </FormGroup>

                        <FormGroup controlId="formHorizontalPassword">
                            <Col smOffset={1} sm={10}>
                                <FormControl type="password" placeholder="Password" />
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col smOffset={1} sm={10}>
                                <Col sm={6}>
                                    <Checkbox>Remember me</Checkbox>                             
                                </Col>
                                <Col sm={6}>
                                    <a href="#forgot" className="pull-right">Forgot password?</a>
                                </Col>
                            </Col>
                        </FormGroup>

                        <FormGroup>
                            <Col sm={12} className="submit">
                                <Button bsStyle="primary" type="submit">Sign in</Button>
                            </Col>
                            <Col sm={12} className="register">
                                <a href="#regoster">Sing up to become a member!</a>
                            </Col>
                        </FormGroup>
                    </Form>                    
                </div>
            </Modal.Body>
        </Modal>    
    );
  }
}

export default SignInModal;