import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap'

class MenuItem extends Component {
    constructor(props){
      super(props);
    }
    render() {
      return (
        <NavItem eventKey={this.props.eventKey} href="#">
            {this.props.title}
        </NavItem>
      );
    }
  }
  
  export default MenuItem;