import React, { Component } from 'react';


class BottomInfo extends Component {
  render() {
    return (
        <div className="container">
            <div className="bottom-info">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <h3>Always reachable</h3>
                        <p>
                            Hella nasne Cosad amcasc aso masco maosc aSC casomcoas 
                            caCOMsaOCMAOMCsacm CaCSSAcasdas dsa dsadsadsa
                            aoscmACSM CoSAMC OSAcm ocsa MOCSA mScosoosos.
                        </p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <p>
                            Lorem ipsun asd oasd ams odas mdoommo
                            dsoamdosadmsaOMDAOSmdOSAMDOAMSd
                            mdsoamdoasmdasomd
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default BottomInfo;